import React from 'react'
import { Link } from 'gatsby'
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Helmet } from 'react-helmet'

import Layout from 'components/Layout'
import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import LinkBack from 'components/LinkBack'

import 'styles/generic-page.scss'

type GenericPageTemplateProps = {
  pageContext: {
    title: string
    content: RenderRichTextData<ContentfulRichTextGatsbyReference>
    metaDescription?: string
  }
}

export default function GenericPageTemplate({
  pageContext: { title, content, metaDescription },
}: GenericPageTemplateProps) {
  const contentOptions: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: {
            file: { url },
          },
        },
      }) => {
        return (
          <div className="generic-page__asset">
            <img className="generic-page__asset__image" src={url} alt="Asset" />
          </div>
        )
      },
      // eslint-disable-next-line react/display-name
      [INLINES.EMBEDDED_ENTRY]: ({
        data: {
          target: { text, url },
        },
      }) => {
        const externalUrlRegexp =
          /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/
        const internalUrlRegexp = /^\/([a-z][a-z0-9]*)(-[a-z0-9]+)*$/
        const mailtoUrlRegexp =
          /mailto:(?:[a-zA-Z0-9!#$&'*+/=?^_`{|}~-]|%[0-9a-fA-F]{2})+(?:\.(?:[a-zA-Z0-9!#$&'*+/=?^_`{|}~-]|%[0-9a-fA-F]{2})+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

        if (url.match(externalUrlRegexp) || url.match(mailtoUrlRegexp))
          return (
            <a className="generic-page__button btn btn-primary" href={url}>
              {text}
            </a>
          )
        if (url.match(internalUrlRegexp))
          return (
            <Link className="generic-page__button btn btn-primary" to={url}>
              {text}
            </Link>
          )
        return null
      },
    },
  }

  return (
    <AuthProvider>
      <UserProvider>
        <Helmet>
          <title>{title}</title>
          {metaDescription && (
            <meta name="description" content={metaDescription} />
          )}
        </Helmet>
        <Layout className="generic-page">
          <div className="row">
            <div className="col-lg-2">
              <LinkBack>&#8592; Go back</LinkBack>
            </div>
            <div className="col-lg-8">
              <h1>{title}</h1>
              {content && renderRichText(content, contentOptions)}
            </div>
          </div>
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
